(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('therapy', {
                parent: 'master',
                url: '/therapy',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.therapy.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/therapy/therapy-list.html',
                        controller: 'TherapyController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('therapy');
                        $translatePartialLoader.addPart('therapyStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitPricing');
                        $translatePartialLoader.addPart('visitType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('therapy.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/therapy/therapy-edition.html',
                        controller: 'TherapyEditionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            programId: null,
                            code: null,
                            name: null,
                            durationDays: null,
                            status: null,
                            notes: null,
                            auto: false,
                            plan: {
                                autoFinish: false,
                                visits: [],
                            },
                        };
                    }

                }
            })
            .state('therapy-detail', {
                parent: 'therapy',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_EDITOR'],
                    pageTitle: 'continuumplatformApp.therapy.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/therapy/therapy-detail.html',
                        controller: 'TherapyDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('therapy');
                        $translatePartialLoader.addPart('therapyStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Therapy', function ($stateParams, Therapy) {
                        return Therapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('therapy-detail.edit', {
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/entities/therapy/therapy-edition.html',
                        controller: 'TherapyEditionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Therapy', function ($stateParams, Therapy) {
                        return Therapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('therapy-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_EDITOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/therapy/therapy-delete-dialog.html',
                        controller: 'TherapyDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Therapy', function (Therapy) {
                                return Therapy.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('therapy', null, {reload: 'therapy'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
