(() => {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitReopenDialogController', VisitReopenDialogController);

    VisitReopenDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'visit', 'Visit'];

    function VisitReopenDialogController($timeout, $scope, $uibModalInstance, visit, Visit) {
        const vm = this;

        vm.visit = visit;

        vm.minLimitDate = new Date();
        vm.limitDate = new Date();
        vm.limitDate.setHours(23);
        vm.limitDate.setMinutes(59);
        vm.limitDate.setSeconds(0);
        vm.limitDate.setMilliseconds(0);

        $timeout(function() {
            angular.element('.form-group:eq(0)>input').focus(); // TODO
        });

        vm.clear = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = () => {
            vm.isSaving = true;
            Visit.reopen({
                id: vm.visit.id,
            }, {
                limitDate: vm.limitDate,
            }, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:visitUpdate', vm.visit.id);
            $uibModalInstance.close(vm.visit);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
