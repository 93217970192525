(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitEditionController', VisitEditionController);

    VisitEditionController.$inject = ['$timeout', '$scope', '$state', 'entity', 'previousState', 'Visit', 'Patient', 'PatientTherapy', 'CareTeam'];

    function VisitEditionController($timeout, $scope, $state, entity, previousState, Visit, Patient, PatientTherapy, CareTeam) {
        const vm = this;

        vm.cancel = cancel;
        vm.save = save;

        vm.previousState = previousState;

        vm.visit = entity;
        vm.patient = vm.visit.patient;

        Visit.getSteps().then(steps => vm.visitSteps = steps);

        // visits cannot be marked as in progress or done manually
        vm.visitStatuses = Visit.visitStatuses.filter(function (element) {
            return (element !== 'IN_PROGRESS' && element !== 'DONE') || element === vm.visit.status;
        });

        vm.visitFrequencies = Visit.getFrequencies();

        if (vm.visit.patientId) {
            loadPatient();
        }

        PatientTherapy.get({id: vm.visit.patientTherapyId}, pt => {
            vm.patientTherapy = pt;

            if (!vm.visit.patientId) {
                vm.visit.patientId = pt.patientId;
                loadPatient();
            }
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>select').focus();
        });

        function loadPatient() {
            Patient.get(
                {id: vm.visit.patientId},
                patient => {
                    vm.patient = patient;

                    CareTeam.getMembers(vm.visit.patientId).then(response => {
                        const team = response.data;
                        Visit.availableMembers(vm.visit.type, team, vm.patient).then((availableAssignees) => {
                            vm.availableAssignees = availableAssignees;
                        });
                    });
                });
        }

        function cancel() {
            $state.go(vm.previousState.name, vm.previousState.params);
        }

        function save() {
            vm.isSaving = true;
            if (vm.visit.id) {
                Visit.update({id: vm.visit.id}, vm.visit, onSaveSuccess, onSaveError);
            } else {
                Visit.save(vm.visit, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:visitUpdate');
            $state.go(vm.previousState.name, vm.previousState.params, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
